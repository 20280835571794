import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import AuthService from "@/auth/authService";

import {isDev} from "@/config/prototypes";
let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class SalesService {
  private static _instance: SalesService;
  config = new API_CONFIG();
  auth = AuthService.getInstance();

  constructor() {
    axios.defaults.baseURL = apiUrl;
    
    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async getPlannerHeader(params: Object = {}) {
    const customizedConfig = {
        ...apiHeaderObj,
        baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
        params: {
          ...this.auth.getAuthObject(),
          ...params
        }
      };

    let dataResults = await axios.get("preset/planner/header", customizedConfig);
    return dataResults.data.resources;
  }

  async getPlannerListByHeader(params: Object = {}) {
    const customizedConfig = {
      ...apiHeaderObj,
      baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
      params: {
        ...this.auth.getAuthObject(),
        ...params
      }
    };

    let dataResults = await axios.get("preset/planner/list", customizedConfig);
    return dataResults.data;
  }

  async getPlannerBoardsList(params: Object = {}) {
    const customizedConfig = {
        ...apiHeaderObj,
        baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
        params: {
          ...this.auth.getAuthObject(),
          ...params
        }
      };

    let dataResults = await axios.get("preset/planner/boards", customizedConfig);
    return dataResults.data.resources;
  }


  static createInstance() {
    SalesService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default SalesService;
