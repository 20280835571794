<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Choose a Board to Edit</div>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Select Board</span>
        <v-select value="id" label="displayName" v-model="selectedBoard" :options="list" @input="onSelectBoard"></v-select>
      </div>

      <hr class="w-100 mx-3 my-4" />

      <div class="col-md-12">
        <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Edit Selected Board</div>
      </div>

      <div class="col-md-12 u-mb-small">
        <span class="c-toolbar__state-title u-text-capitalize">Board Description</span>
        <input class="form-control" type="text" v-model="description" />
      </div>

      <!-- <div class="col-md-12  u-mb-small">
        <span class="c-toolbar__state-title u-text-capitalize">Stages</span>
        <v-select value="id" label="displayName" :options="selectedBoard ? selectedBoard.stages : []" v-model="selectedStage"></v-select>
      </div> -->

      <div class="col-md-12 mt-3">
        <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">3</span>Manage Stages to Board</div>
      </div>

      <div class="col-md-10  u-mb-small">
        <span class="c-toolbar__state-title u-text-capitalize">Stages</span>
        <v-select value="id" label="displayName" v-model="selectedStage" :options="listStage" :clearable="false" :disabled="!selectedBoard"></v-select>
      </div>

      <div class="col-md-2 mt-4">
        <button type="button" class="btn ss-info-bg border-0 btn-secondary btn-md w-100" @click.prevent="addStage" :disabled="!selectedBoard"><i class="fa fa-plus"></i> Add Stage</button>
      </div>

      <div class="col-md-12 mt-3">
        <div class="row">
          <div class="col-md-4 mb-4" v-for="(s, index) in selectedBoardList" :key="index">
            <b-card class="dash-grey-card ">
              <i class="fa fa-times board-remove position-absolute top-0 right-0 mr-3 mt-2 clickable" @click.prevent="removeStage(index)"></i>
              <h5 class="mb-0 font-weight-bold">{{ s.displayName }}</h5>
              <small class="d-block">{{ s.description }}</small>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import SalesService from "@/services/sales";
  const sales = new SalesService();
  const global = new GlobalServices();
  export default {
    name: "ManageBoard",
    data() {
      return {
        selectedBoard: null,
        selectedStage: null,
        list: [],
        listStage: [],
        description: null,
        showErrorMessage: false,
      };
    },
    computed: {
      selectedBoardList() {
        return this.selectedBoard ? this.selectedBoard.stages : [];
      },
      selectedBoardIndex() {
        if (!this.selectedBoard) return -1;
        return this.list.findIndex((d) => d.id === this.selectedBoard.id);
      },
    },
    methods: {
      async init() {
        this.list = await sales.getPlannerBoardsList();
        this.listStage = await global.getUXDropDown(MODULE.LEADS.UX.LeadStages);
      },
      onSelectBoard(board) {
        this.description = board.description;
      },
      removeStage(index) {
        this.selectedBoard.stages.splice(index, 1);
      },
      addStage() {
        if (!this.selectedStage || !this.selectedBoard) return;
        this.list[this.selectedBoardIndex].stages.push({
          displayName: this.selectedStage.displayName,
        });
        this.selectedStage = null;
      },
    },
    mounted() {
      this.init();
    },
  };
</script>
<style>
  .planner-swatch .vue-swatches__trigger {
    width: 60px !important;
  }
  .stage-border {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px 0px 4px 4px !important;
  }
</style>
